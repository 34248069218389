import React, { FC } from 'react';
import styles from './index.module.scss';
import { Row, Col, Button } from 'reactstrap';
import classnames from 'classnames';
import _ from 'lodash';
import Truncate from 'react-truncate';

// Components
import DateTime from '../Cards/CardsDateTime/CardsDateTime';
import CardsImage from '../Cards/CardsImage/CardsImage';
import GetURLParamQuery from '../../libs/GetURLParamQuery';

export interface SectionFeaturedProps {
  channelTalkData: channelTalkData[];
  type: 'channel';
  sectionTitle: 'Latest Talk';
  utmParams?: string;
  dataBddTitle?: string;
  dataBddDesc?: string;
  dataBddRegisterButton?: string;
  priorityStatus?: boolean;
  showTime: boolean;
}

export interface channelTalkData {
  id: number;
  title: string;
  description: string;
  scheduledStartDate: Date;
  scheduledEndDate: Date;
  visibility: string;
  imageUrl: string;
  companyLogo: string;
  primaryColor: string;
  secondaryColor: string;
  tracks: Track[];
  featured: boolean;
  channel: number;
  status: string;
}

export interface Track {
  id: number;
  name: string;
  communications: Communication[];
}

export interface Communication {
  channelId: number;
  communicationId: number;
}

// To find the logo in a array of links  with no specific key
// we need to loop out the links array in the return and look for the
// title key matching to preview, and then grab the href key value to
// pass to the next image function.

function getChannelImage(linksArray, imageType) {
  if (!linksArray) return '';

  for (const { href, title } of linksArray) {
    if (imageType === 'preview' && title === 'preview') {
      return href;
    }
  }

  return '';
}

const SectionFeaturedChannel: FC<SectionFeaturedProps> = ({
  channelTalkData,
  type,
  sectionTitle,
  utmParams,
  dataBddTitle,
  dataBddDesc,
  dataBddRegisterButton,
  priorityStatus,
  showTime = true,
}) => {
  let buttonLabel = 'Register';

  if (channelTalkData['status'] === 'recorded') {
    buttonLabel = 'Watch';
  }

  const updatedUtm = GetURLParamQuery(utmParams)

  return (
    <div
      className={classnames(
        styles['bt-section-featured'],
        styles['channel-featured-talk'],
        'generic-featured'
      )}
      data-bdd={` status-${channelTalkData['status']}-talk`}
    >
      <Row className={styles['row-left']}>
        <Col md="6" lg="4" className={styles['featured-left']}>
          <div
            className={classnames(
              styles['img-wrap'],
              styles['section-featured-img-holder']
            )}
          >
            <a
              href={
                '/webcast/' +
                channelTalkData['channel']['id'] +
                '/' +
                channelTalkData['id'] +
                updatedUtm
              }
            >
              <CardsImage
                id={channelTalkData['id']}
                href={getChannelImage(channelTalkData['links'], 'preview')}
                title={channelTalkData['title']}
                pageType="section-featured"
                priorityStatus={priorityStatus}
              />
            </a>
          </div>
        </Col>
        <Col md="6" lg="8" className={styles['featured-right']}>
          <Row className={styles['row-right']}>
            <Col
              className={
                type === 'channel' && styles['bt-section-featured-body']
              }
            >
              <h2 className={styles['featured-section-title']}>
                {sectionTitle}
              </h2>

              <h3
                data-bdd={dataBddTitle}
                className={styles['latest-webcast-title']}
              >
                <a
                  href={
                    '/webcast/' +
                    channelTalkData['channel']['id'] +
                    '/' +
                    channelTalkData['id'] +
                    updatedUtm
                  }
                >
                  {channelTalkData['title']}
                </a>
              </h3>

              <p className={styles['item-meta']}>
                <DateTime
                  id={'datetime-' + channelTalkData['id']}
                  dateTime={channelTalkData['start']}
                  endDate={channelTalkData['closeTime']}
                  fromType="talk"
                  duration={channelTalkData['duration']}
                  showTime={showTime}
                  status={channelTalkData['status']}
                />
              </p>

              <p className={styles['description']} data-bdd={dataBddDesc}>
                <Truncate lines={2}>{channelTalkData['description']}</Truncate>
              </p>

              <Button
                href={
                  '/webcast/' +
                  channelTalkData['channel']['id'] +
                  '/' +
                  channelTalkData['id'] +
                  updatedUtm
                }
                className={classnames(
                  styles['bt-button-regular-primary-short'],
                  styles['bt-elastic']
                )}
                data-bdd={dataBddRegisterButton}
              >
                {buttonLabel}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default SectionFeaturedChannel;
